import React, { useEffect, useState } from 'react';
import './navigation.scss';
import { useMoralis } from 'react-moralis';
import hamburger from '../../assets/images/Hamburger.svg';
import { useNavigate } from 'react-router-dom';

const Navigation = () => {
  const {
    authenticate,
    isAuthenticated,
    isAuthenticating,
    user,
    account,
    logout,
    Moralis,
    isInitialized
  } = useMoralis();
  const navigate = useNavigate();
  const [currentUser, setCurrentUser] = useState(undefined);
  const [currentAccount, setCurrentAccount] = useState(undefined);

  const setUserAndAccount = () => {
    if (isAuthenticated) {
      setCurrentUser(Moralis.User.current());
      setCurrentAccount(Moralis.User.current().get('ethAddress'));
    }
  };

  useEffect(() => {
    if (isInitialized) {
      setUserAndAccount();
    }
  }, [user, isAuthenticated]);

  const login = async () => {
    if (!isAuthenticated) {
      await authenticate({ signingMessage: 'Log in using Expander' })
        .then(function (user) {})
        .catch(function (error) {
          console.log(error);
        });
    }
  };

  const logOut = async () => {
    setCurrentAccount(undefined);
    logout();
  };

  const handleProfileClick = () => {
    navigate('/profile');
  };

  const handleHomePageClick = () => {
    navigate('/');
  };

  return (
    <div className="navbar">
      <div className="navbar__container">
        <div className="nav-left">
          <div className="nav-logo">
            <h3 onClick={handleHomePageClick}>Expandr</h3>
          </div>
        </div>
        <div className="nav-right">
          <div className="walletConnection">
            {currentAccount ? (
              <>
                <div className="connectedState">
                  <h4>
                    Wallet Connected <span> </span>
                  </h4>
                  <p>{currentAccount}</p>
                </div>
                <button className="ctwBtn" onClick={logOut}>
                  Disconnect
                </button>
              </>
            ) : (
              <>
                <button className="ctwBtn" onClick={login}>
                  {'Connect to wallet'}
                </button>
              </>
            )}
          </div>
          {currentAccount && (
            <div className="hamburgerMenu">
              <img src={hamburger} alt="menu" />
              <div className="dropdown-content">
                <a onClick={handleProfileClick}>Profile</a>
                <a onClick={logOut}>Logout</a>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Navigation;
