import React from 'react';
import dp from '../../../assets/images/dp.webp';
import * as styles from './feedCard.module.scss';

const FeedCard = () => {
  return (
    <div className={styles.feedCard}>
      <div className={styles.posterInfo}>
        <div className={styles.dp}>
          <img src={dp} alt="dp" />
        </div>
        <div className={styles.name}>
          <h4>ajax490</h4>
          <p>02 Jul 2022 at 14:20</p>
        </div>
      </div>
      <div className={styles.content}>
        <p>Hellow world checkout this video i posted</p>
      </div>
    </div>
  );
};

export default FeedCard;
