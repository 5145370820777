import React from 'react';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import ParentContainer from '../components/parentContainer';
import SubscriberCard from '../components/subscribeCard/subscriberCard';
import CreatorPage from './creator/creatorPage';
import Home from './home/home';
import CreatorSignup from './signup/creatorSignup';
import Login from './login/login';
import Subscribe from './subscriber/subscribe';
import Confirmation from '../components/Confirmation/Confirmation';
import Profile from './profile/profile';
import Navigation from '../components/navigation/Navigation';
import Manage from './manage/manage';
import Landingpage from './landingpage/landingpage';

export const Routing = (props) => {
  return (
    <>
      <Router>
        <Navigation />

        <Routes>
          <Route
            exact
            path="/home"
            element={
              <ParentContainer {...props}>
                <Home {...props} />
              </ParentContainer>
            }
          />
          <Route
            exact
            path="/profile"
            element={
              <ParentContainer {...props}>
                <Profile {...props} />
              </ParentContainer>
            }
          />
          <Route
            exact
            path="/manage"
            element={
              <ParentContainer {...props}>
                <Manage {...props} />
              </ParentContainer>
            }
          />
          <Route
            exact
            path="/subscribe"
            element={
              <ParentContainer {...props}>
                <Subscribe {...props} />
              </ParentContainer>
            }
          />
          <Route
            exact
            path="/confirmation"
            element={
              <ParentContainer {...props}>
                <Confirmation {...props} />
              </ParentContainer>
            }
          />
          <Route
            exact
            path="/:username/subscribe"
            element={
              <ParentContainer {...props}>
                <SubscriberCard {...props} />
              </ParentContainer>
            }
          />
          <Route exact path="/sat" element={<SubscriberCard />} />
          <Route exact path="/creator" element={<CreatorPage />} />
          <Route exact path="/signup" element={<CreatorSignup />} />
          <Route exact path="/login" element={<Login />} />
          <Route exact path="/johndoe" element={<CreatorPage />} />

          <Route exact path="/" element={<Landingpage />} />
          <Route path="/:username" element={<CreatorPage />} />
        </Routes>
      </Router>
    </>
  );
};
