import React from 'react';
import * as styles from './create.module.scss';
import cross from '../../assets/images/cross.svg';

const Create = ({ handleClose }) => {
  return (
    <div className={styles.create}>
      <div className={styles.container}>
        <div className={styles.header}>
          <h3>Create a post</h3>
          <img src={cross} alt="x" onClick={handleClose} />
        </div>
        <div className={styles.inputField}>
          <textarea />
        </div>
        <div className={styles.submit}>
          <button>PUBLISH</button>
        </div>
      </div>
    </div>
  );
};

export default Create;
