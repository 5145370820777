import React, { useEffect, useState } from 'react';
import { useMoralis } from 'react-moralis';
import * as styles from './home.module.scss';
import ProfileStats from '../../components/homepage/profileStats/profileStats';
import FeedCard from '../../components/homepage/feedCard/feedCard';
import { useNavigate } from 'react-router-dom';
import Create from '../../components/create/create';

export default function Home() {
  const {
    authenticate,
    isAuthenticated,
    isAuthenticating,
    user,
    account,
    logout,
    Moralis,
    web3,
    isWeb3Enabled
  } = useMoralis();

  const [currentUser, setCurrentUser] = useState(undefined);
  const [currentAccount, setCurrentAccount] = useState(undefined);
  const [create, setCreate] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    if (isAuthenticated) {
      // add your logic here
      setCurrentUser(Moralis.User.current());
      setCurrentAccount(Moralis.User.current().get('ethAddress'));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  const handleCreateClick = () => {
    setCreate(true);
  };

  const handleCloseCreate = () => {
    setCreate(false);
  };

  const handleSeeMoreClick = () => {
    navigate('/manage');
  };
  return (
    <div className="">
      {create && <Create handleClose={handleCloseCreate} />}
      <div className={styles.homepage}>
        <div className={styles.profileStats}>
          <ProfileStats />
        </div>
        <div className={styles.feed}>
          <FeedCard />
          <FeedCard />
          <FeedCard />
          <FeedCard />
          <FeedCard />
        </div>
        <div className={styles.create}>
          <button onClick={handleCreateClick}>CREATE POST</button>
          <div className={styles.subscriberFeed}>
            <h3>Who's subscribing? </h3>
            <div className={styles.feedItem}>
              <p>
                <span>apd3ds</span> just subscribed to your for <b>0.02ETH</b> for <b>4 months</b>!
              </p>
              <h6>02 Jul 2022 at 14:20</h6>
            </div>
            <div className={styles.feedItem}>
              <p>
                <span>apd3ds</span> just subscribed to your for <b>0.02ETH</b> for <b>4 months</b>!
              </p>
              <h6>02 Jul 2022 at 14:20</h6>
            </div>
            <div className={styles.showMore}>
              <h4 onClick={handleSeeMoreClick}>See more</h4>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
