import React from 'react';
import * as styles from './profileStats.module.scss';
import dp from '../../../assets/images/bayc2.png';

const ProfileStats = ({ username, address }) => {
  return (
    <div className={styles.profileStatsMain}>
      <div className={styles.container}>
        <div className={styles.dp}>
          <img src={dp} alt="dp" />
          <h3>{username}</h3>
          <p>{address}</p>
        </div>
        <div className={styles.stats}>
          <div className={styles.stat}>
            <div className={styles.titleName}>
              <h3>Subscribers</h3>
            </div>
            <div className={styles.titleValue}>
              <h4>123434</h4>
            </div>
          </div>
          <div className={styles.stat}>
            <div className={styles.titleName}>
              <h3>Followers</h3>
            </div>
            <div className={styles.titleValue}>
              <h4>123434</h4>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileStats;
