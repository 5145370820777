import React from "react";
import Signup from "../../components/signup/signup";

const CreatorSignup = () => {
  return (
    <div>
      <Signup />
    </div>
  );
};

export default CreatorSignup;
