import React from 'react';
import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { MetaMaskContext } from '../../hooks/useMetaMask';
import { useMoralis, useMoralisQuery } from 'react-moralis';
import Subscribe from '../subscriber/subscribe';
const CreatorPage = (props) => {
  const navigate = useNavigate();
  const params = useParams();
  const [currentUser, setCurrentUser] = useState(undefined);
  const [currentAccount, setCurrentAccount] = useState(undefined);
  const [subscribedUser, setSubscribedUser] = useState(false);
  const pageName = window.location.pathname.slice(1);
  const [showPage, setShowPage] = useState(false);
  const [creatorAddress, setCreatorAddress] = useState('');

  const { isAuthenticated, user, account, Moralis, isInitialized } = useMoralis();
  const setUserAndAccount = () => {
    if (isAuthenticated) {
      setCurrentAccount(Moralis.User.current().get('ethAddress'));
    } else {
      setCurrentAccount(undefined);
    }
  };

  const setPage = async () => {
    const username = params.username;
    if (await doesUserIdExist(username)) {
      setShowPage(true);
      setCreatorAddress(await getWalletFromUserId(username));
    }
  };

  // const { fetch } = useMoralisQuery(
  //   'Subscription',
  //   (query) => query.equalTo('senderAddress', Moralis.User.current().get('ethAddress')),
  //   [],
  //   { autoFetch: false }
  // );

  const getSubscriberState = async () => {
    const username = params.username;
    const query = new Moralis.Query('Subscription');
    query.equalTo('senderAddress', Moralis.User.current().get('ethAddress'));
    // run query
    const results = await query.find();
    results.forEach((contract) => {
      if (contract.attributes.creatorId === username) {
        setSubscribedUser(true);
      }
    });
  };

  useEffect(() => {
    setUserAndAccount();
    if (isInitialized) {
      setPage();
      if (isAuthenticated) {
        getSubscriberState();
      }
    }
  }, [isAuthenticated, window.location.pathname, isInitialized]);

  async function doesUserIdExist(userId) {
    // create query
    const query = new Moralis.Query('Creator');
    query.equalTo('userId', userId);

    // run query
    const results = await query.find();
    return results.length > 0;
  }

  async function getWalletFromUserId(userId) {
    const query = new Moralis.Query('Creator');
    query.equalTo('userId', userId);
    query.select('walletAddress');
    const results = await query.find();
    const resultSet = await JSON.stringify(results[0]);
    const resultParsed = await JSON.parse(resultSet);
    return resultParsed['walletAddress'];
  }
  return (
    <Subscribe
      creatorAddress={creatorAddress}
      creatorId={pageName}
      subscribedUser={subscribedUser}
    />
  );

  // if (showPage) {
  //   return <Subscribe creatorAddress={creatorAddress} creatorId={pageName} />;
  // } else {
  //   return (
  //     <div>
  //       Sorry, <b>{pageName}</b> does not exist
  //     </div>
  //   );
  // }
};

export default CreatorPage;
