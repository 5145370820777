import React from 'react';
import Card from '../card/card';
import SignupForm from './signup-form';
import './signup.scss';

const Signup = () => {
  return (
    <div className="signupPage">
      <Card>
        <SignupForm />
      </Card>
    </div>
  );
};

export default Signup;
