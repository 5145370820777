import React from 'react';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import CrossBtn from '../../assets/images/cross.svg';
import { MetaMaskContext } from '../../hooks/useMetaMask';
import { useMoralis, useMoralisQuery } from 'react-moralis';
import ExpanderCreatorFactory from '../../contracts/ExpanderCreatorFactory.sol/ExpanderCreatorFactory.json';
import {
  creatorContractAddress,
  mumbaiConfig,
  fujiConfig,
  PRIVATE_KEY_ACCOUNT
} from '../../ContractConst';
const SignupForm = () => {
  const navigate = useNavigate();

  const { isAuthenticated, user, account, Moralis, enableWeb3 } = useMoralis();

  const ethers = Moralis.web3Library;

  const handleCloseBtnClick = () => {
    navigate('/');
  };

  const [currentUser, setCurrentUser] = useState(undefined);
  const [currentAccount, setCurrentAccount] = useState(undefined);
  const [showError, setShowError] = useState(false);

  const setUserAndAccount = () => {
    if (isAuthenticated) {
      setCurrentUser(Moralis.User.current());
      setCurrentAccount(Moralis.User.current().get('ethAddress'));
    } else {
      setCurrentAccount(undefined);
    }
  };

  useEffect(() => {
    setUserAndAccount();
  }, [isAuthenticated]);

  const [formData, setFormData] = useState({});

  const handleInputChange = async (e) => {
    if (e.target.name === 'userId') {
      setShowError(await doesUserIdExist(e.target.value));
    }
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  async function doesUserIdExist(userId) {
    // create query
    const query = new Moralis.Query('Creator');
    query.equalTo('userId', userId);

    // run query
    const results = await query.find();
    return results.length > 0;
  }

  const createCreatorOnContract = async () => {
    const provider = new ethers.providers.JsonRpcProvider(
      'https://polygon-mumbai.g.alchemy.com/v2/1v17D1vEw7wqy6LDAWBItw0iYcE37Ztm',
      mumbaiConfig.chainId
    );
    const signer = new ethers.Wallet(PRIVATE_KEY_ACCOUNT, provider);
    const creatorContract = new ethers.Contract(
      mumbaiConfig.ExpanderCreatorFactory,
      ExpanderCreatorFactory.abi,
      signer
    );
    const estimatedGasLimit = await creatorContract.estimateGas.addCreator(
      currentAccount,
      ethers.BigNumber.from(mumbaiConfig.chainId),
      mumbaiConfig.name,
      formData.userId
    );
    const approveTxUnsigned = await creatorContract.populateTransaction.addCreator(
      currentAccount,
      ethers.BigNumber.from(mumbaiConfig.chainId),
      mumbaiConfig.name,
      formData.userId
    );
    approveTxUnsigned.chainId = mumbaiConfig.chainId;
    approveTxUnsigned.gasLimit = estimatedGasLimit;
    approveTxUnsigned.gasPrice = await provider.getGasPrice();
    approveTxUnsigned.nonce = await provider.getTransactionCount(signer.address);
    const approveTxSigned = await signer.signTransaction(approveTxUnsigned);
    const submittedTx = await provider.sendTransaction(approveTxSigned);
    const approveReceipt = await submittedTx.wait();
  };

  const HandleSubmit = async (e) => {
    if (await doesUserIdExist(formData.userId)) {
      e.preventDefault();
      setShowError(true);
      return;
    }

    const web3 = await Moralis.enableWeb3({
      // provider: 'metamask'
      privateKey: PRIVATE_KEY_ACCOUNT
    });
    const chain = mumbaiConfig;

    const options = {
      contractAddress: mumbaiConfig.ExpanderCreatorFactory,
      functionName: 'addCreator',
      abi: ExpanderCreatorFactory.abi,
      params: {
        _creatorAddress: currentAccount,
        _chainId: chain.chainId,
        _chainName: chain.name,
        _userId: formData.userId
      }
    };
    const readOptions = {
      contractAddress: mumbaiConfig.ExpanderCreatorFactory,
      functionName: 'getNumberOfCreators',
      abi: ExpanderCreatorFactory.abi
    };

    const getCreatorOptions = {
      contractAddress: mumbaiConfig.ExpanderCreatorFactory,
      functionName: 'getCreatorFromUserId',
      abi: ExpanderCreatorFactory.abi,
      params: {
        _userId: 'account4'
      }
    };
    // const getCreatorTransaction = await Moralis.executeFunction(getCreatorOptions);
    // console.log('Creator info is ', getCreatorTransaction);
    // const readTransaction = await Moralis.executeFunction(readOptions);
    // console.log('number of creators is', readTransaction);
    // return;

    const Creator = Moralis.Object.extend('Creator');
    const creator = new Creator();
    creator.set('userId', formData.userId);
    creator.set('email', formData.emailId);
    creator.set('walletAddress', currentAccount);
    creator.set('chainName', chain.name);
    creator.set('chainId', chain.chainId);
    const user = Moralis.User.current().set('email', formData.emailId);
    await user.save();
    creator.save();
    navigate('/' + formData.userId);
    await createCreatorOnContract();
    // const transaction = await Moralis.executeFunction(options);
    // const receipt = await transaction.wait();
    // console.log(receipt.status);
    // if (receipt.status === 1) {
    // } else {
    //   setShowError(true);
    // }
    // console.log(receipt);

    e.preventDefault();
  };
  const walletAddress = account;
  return (
    <div className="signupForm">
      <div className="signupForm__closeBtn" onClick={handleCloseBtnClick}>
        <img src={CrossBtn} alt="x" />
      </div>
      <div className="signupForm__title">
        <h3>Sign up.</h3>
        <p>Take your first step to joining the creator economy</p>
      </div>
      <div className="signupForm__form">
        <div className="inputFormElement">
          <label>USER ID {showError ? ' - ALREADY TAKEN, TRY ANOTHER ONE' : null}</label>
          <input className="inputBox" name="userId" onChange={handleInputChange} />
        </div>

        <div className="inputFormElement">
          <label>EMAIL ID</label>
          <input className="inputBox" name="emailId" onChange={handleInputChange} />
        </div>
        <div className="inputFormElement">
          <label>WALLET ADDRESS</label>
          <input
            className="inputBox"
            name="walletAddress"
            placeholder={currentAccount === undefined ? 'Connect to wallet' : currentAccount}
          />
        </div>
        <div className="signupBtn">
          <button onClick={HandleSubmit}>Sign up</button>
        </div>
      </div>
    </div>
  );
};

export default SignupForm;
