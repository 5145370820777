import React from 'react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import dp from '../../assets/images/dp.webp';
import * as styles from './manage.module.scss';

const Manage = () => {
  const navigate = useNavigate();
  const [view, setView] = useState('subscription');
  const handleHomeClick = () => {
    navigate('/home');
  };

  const handleViewClick = (state) => setView(state);

  return (
    <div className={styles.manageAccount}>
      <div className={styles.container}>
        <h6 className={styles.breadcrumb} onClick={handleHomeClick}>
          Home /
        </h6>

        <div className={styles.header}>
          <div className={styles.sectionName}>
            <h3>Manage account</h3>
            <p>Manage your subscriptions and subscribers from here.</p>
          </div>
          <div className={styles.stats}>
            <div className={styles.items}>
              <h3>40,458</h3>
              <p>Subscribers</p>
            </div>
            <div className={styles.items}>
              <h3>10,458</h3>
              <p>Following</p>
            </div>
          </div>
        </div>
        <div className={styles.body}>
          <div className={styles.viewToggle}>
            <button
              onClick={() => handleViewClick('subscription')}
              className={view === 'subscription' ? `${styles.active}` : ''}>
              Subscriptions
            </button>
            <button
              onClick={() => handleViewClick('following')}
              className={view === 'following' ? `${styles.active}` : ''}>
              Following
            </button>
          </div>
          <div className={styles.userList}>
            <div className={styles.userCard}>
              <img src={dp} alt="dp" />
              <div className={styles.details}>
                <div className={styles.profileDetails}>
                  <div className={styles.name}>
                    <h4>johnDoe123</h4>
                    <p>0xasd13123df...</p>
                  </div>
                  <div className={styles.visitProfile}>
                    <button>VISIT PROFILE</button>
                  </div>
                </div>
                <div className={styles.earningDetails}>
                  <div className={styles.amount}>
                    <h4>40 MATIC / week</h4>
                    <p>Since 21/05/2022 (1w)</p>
                  </div>
                  <div className={styles.earning}>
                    <p>Total Earnings</p>
                    <h4>320.000 MATIC</h4>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.userCard}>
              <img src={dp} alt="dp" />
              <div className={styles.details}>
                <div className={styles.profileDetails}>
                  <div className={styles.name}>
                    <h4>johnDoe123</h4>
                    <p>0xasd13123df...</p>
                  </div>
                  <div className={styles.visitProfile}>
                    <button>VISIT PROFILE</button>
                  </div>
                </div>
                <div className={styles.earningDetails}>
                  <div className={styles.amount}>
                    <h4>40 MATIC / week</h4>
                    <p>Since 21/05/2022 (1w)</p>
                  </div>
                  <div className={styles.earning}>
                    <p>Total Earnings</p>
                    <h4>320.000 MATIC</h4>
                  </div>
                </div>
              </div>
            </div>

            <div className={styles.userCard}>
              <img src={dp} alt="dp" />
              <div className={styles.details}>
                <div className={styles.profileDetails}>
                  <div className={styles.name}>
                    <h4>johnDoe123</h4>
                    <p>0xasd13123df...</p>
                  </div>
                  <div className={styles.visitProfile}>
                    <button>VISIT PROFILE</button>
                  </div>
                </div>
                <div className={styles.earningDetails}>
                  <div className={styles.amount}>
                    <h4>40 MATIC / week</h4>
                    <p>Since 21/05/2022 (1w)</p>
                  </div>
                  <div className={styles.earning}>
                    <p>Total Earnings</p>
                    <h4>320.000 MATIC</h4>
                  </div>
                </div>
              </div>
            </div>

            <div className={styles.userCard}>
              <img src={dp} alt="dp" />
              <div className={styles.details}>
                <div className={styles.profileDetails}>
                  <div className={styles.name}>
                    <h4>johnDoe123</h4>
                    <p>0xasd13123df...</p>
                  </div>
                  <div className={styles.visitProfile}>
                    <button>VISIT PROFILE</button>
                  </div>
                </div>
                <div className={styles.earningDetails}>
                  <div className={styles.amount}>
                    <h4>40 MATIC / week</h4>
                    <p>Since 21/05/2022 (1w)</p>
                  </div>
                  <div className={styles.earning}>
                    <p>Total Earnings</p>
                    <h4>320.000 MATIC</h4>
                  </div>
                </div>
              </div>
            </div>

            <div className={styles.userCard}>
              <img src={dp} alt="dp" />
              <div className={styles.details}>
                <div className={styles.profileDetails}>
                  <div className={styles.name}>
                    <h4>johnDoe123</h4>
                    <p>0xasd13123df...</p>
                  </div>
                  <div className={styles.visitProfile}>
                    <button>VISIT PROFILE</button>
                  </div>
                </div>
                <div className={styles.earningDetails}>
                  <div className={styles.amount}>
                    <h4>40 MATIC / week</h4>
                    <p>Since 21/05/2022 (1w)</p>
                  </div>
                  <div className={styles.earning}>
                    <p>Total Earnings</p>
                    <h4>320.000 MATIC</h4>
                  </div>
                </div>
              </div>
            </div>

            <div className={styles.userCard}>
              <img src={dp} alt="dp" />
              <div className={styles.details}>
                <div className={styles.profileDetails}>
                  <div className={styles.name}>
                    <h4>johnDoe123</h4>
                    <p>0xasd13123df...</p>
                  </div>
                  <div className={styles.visitProfile}>
                    <button>VISIT PROFILE</button>
                  </div>
                </div>
                <div className={styles.earningDetails}>
                  <div className={styles.amount}>
                    <h4>40 MATIC / week</h4>
                    <p>Since 21/05/2022 (1w)</p>
                  </div>
                  <div className={styles.earning}>
                    <p>Total Earnings</p>
                    <h4>320.000 MATIC</h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Manage;
