import React from 'react';
import CheckoutCard from '../../components/checkout/checkoutCard';
import Card from '../card/card';
import * as styles from './confirmation.module.scss';

const Confirmation = (props) => {
  return (
    <div className={styles.thankyou}>
      <Card cardClassName={styles.thankyouCard}>
        <div className={styles.container}>
          <div className={styles.title}>
            <h2>Thank you!</h2>
            <h4>You're now subscribed!</h4>
          </div>
          <div className={styles.cta}>
            <button>Check them out!</button>
          </div>
        </div>
      </Card>
    </div>
  );
};

export default Confirmation;
