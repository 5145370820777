import React, { useEffect, useState } from 'react';
import { useMoralis } from 'react-moralis';
import { useNavigate } from 'react-router-dom';

const ParentContainer = ({ children }) => {
  const { isAuthenticated, user, Moralis, isInitialized } = useMoralis();

  const navigate = useNavigate();

  const [currentUser, setCurrentUser] = useState(undefined);

  const setUserAndAccount = () => {
    if (isAuthenticated) {
      setCurrentUser(Moralis.User.current());
    } else {
      navigate('/login');
    }
  };

  useEffect(() => {
    if (isInitialized) {
      setUserAndAccount();
    } else {
      navigate('/login');
    }
  }, [user, isAuthenticated]);

  return <>{currentUser && <>{children}</>} </>;
};

export default ParentContainer;
