import React, { useState, useEffect } from 'react';
import Card from '../card/card';
import './checkoutCard.scss';
import dp from '../../assets/images/bayc2.png';
import metamask from '../../assets/images/MetaMask_Fox.svg';
import CrossBtn from '../../assets/images/cross.svg';
import { useMoralis, useMoralisWeb3Api } from 'react-moralis';

import { useNavigate } from 'react-router-dom';
import ExpanderSubscriptions from '../../contracts/ExpanderSubscriptions.sol/ExpanderSubscriptions.json';
import Web3 from 'web3';
import WETH from '../../contracts/Token.sol/WETH.json';
import axios from 'axios';
import {
  paymentContract,
  ERC20Address,
  subscriptionContractAddress,
  bytes32FixedValue,
  PRIVATE_KEY_ACCOUNT,
  fujiConfig,
  mumbaiConfig
} from '../../ContractConst';
const CheckoutCard = ({ handleCloseBtn, ...props }) => {
  const navigate = useNavigate();
  const [amount, setAmount] = useState('');
  const [amountIncomplete, setAmountIncomplete] = useState(false);
  const [duration, setDuration] = useState('');
  const [durationIncomplete, setDurationIncomplete] = useState(false);
  const [frequency, setFrequency] = useState('');
  const [frequencyIncomplete, setFrequencyIncomplete] = useState(false);
  const [validForm, setValidForm] = useState(false);
  const [updateHook, setUpdateHook] = useState(false);
  const [allTokensBalance, setAllTokensBalance] = useState([]);
  const [selectedToken, setSelectedToken] = useState('');
  const [tokenBalance, setTokenBalance] = useState(0);

  const chains = ['bsc testnet', 'mumbai', 'avalanche testnet'];
  const { authenticate, Moralis, isAuthenticated, account } = useMoralis();
  const ethers = Moralis.web3Library;

  const Web3Api = useMoralisWeb3Api();

  const handleFrequencyClick = (type) => {
    setFrequency(type);
    if (!updateHook) {
      setUpdateHook(true);
    } else {
      setUpdateHook(false);
    }
  };

  // const validate
  const handleAmountChange = (e) => {
    const { value } = e.target;
    setAmount(value);

    if (value.length > 0 && value !== '0') {
      setAmountIncomplete(false);
    } else {
      setAmountIncomplete(true);
    }
  };

  const handleDurationChange = (e) => {
    // This is currently a bit faulty, so deleting it, but we should fix it
    // if (/^\d*$/.test(e.target.value)) {
    //   setDuration(e.target.value);
    // }
    const { value } = e.target;
    setDuration(value);

    if (value.length > 0 && value !== '0') {
      setDurationIncomplete(false);
    } else {
      setDurationIncomplete(true);
    }
  };

  const getTokenBalance = async () => {
    if (isAuthenticated) {
      const t = await Promise.all(
        chains.map(async (chain) => {
          const options = {
            chain: chain,
            address: Moralis.User.current().get('ethAddress')
          };
          const balance = await Web3Api.account.getTokenBalances(options);
          return balance;
        })
      );

      const balance = [].concat(...t);
      setAllTokensBalance(balance);
    }
  };

  useEffect(() => {
    getTokenBalance();
  }, [isAuthenticated]);

  useEffect(() => {
    if (amount.length > 0 && duration.length > 0 && frequency !== '') {
      setValidForm(true);
    } else {
      setValidForm(false);
    }
  }, [amount, duration, frequency]);

  const handleTokenChange = (e) => {
    const tokenObj = allTokensBalance.filter((token) => token.symbol === e.target.value);
    if (tokenObj.length > 0) {
      const balance = tokenObj[0].balance;
      setTokenBalance(Moralis.Units.FromWei(balance, tokenObj[0].decimals));
      setSelectedToken(e.target.value);
    }
  };
  const getGasPrice = async (env, source, destination, tokenAddress) => {
    if (env == 'local') return 1;
    if (env != 'testnet') throw Error('env needs to be "local" or "testnet".');
    const api_url = 'https://devnet.api.gmp.axelarscan.io';

    const requester = axios.create({ baseURL: api_url });
    const params = {
      method: 'getGasPrice',
      destinationChain: destination.name,
      sourceChain: source.name
    };

    // set gas token address to params
    if (tokenAddress != ethers.constants.AddressZero) {
      params.sourceTokenAddress = tokenAddress;
    } else {
      params.sourceTokenSymbol = source.tokenSymbol;
    }
    // send request
    const response = await requester.get('/', { params }).catch((error) => {
      return { data: { error } };
    });
    const result = response.data.result;
    const dest = result.destination_native_token;
    const destPrice = 1e18 * dest.gas_price * dest.token_price.usd;
    return destPrice / result.source_token.token_price.usd;
  };

  const handlePayment = async (bytes32uniqueId) => {
    const gasPrice = Math.floor(
      (await getGasPrice(
        'testnet',
        fujiConfig,
        mumbaiConfig,
        fujiConfig.availableTokens.aUSDC.tokenAddress
      )) *
        5 *
        8 *
        50
    );
    const gasLimit = 300000;
    const multiplier = 1.5;
    const gas = ethers.BigNumber.from(53696936926466000n);
    // const rpcProvider = new ethers.providers.JsonRpcProvider(fujiConfig.rpc, fujiConfig.chainId);
    const provider = new ethers.providers.Web3Provider(window.ethereum, 'any');
    provider.on('network', (newNetwork, oldNetwork) => {
      // When a Provider makes its initial connection, it emits a "network"
      // event with a null oldNetwork along with the newNetwork. So, if the
      // oldNetwork exists, it represents a changing network
      if (oldNetwork) {
        window.location.reload();
      }
    });
    const signer = new ethers.Wallet(PRIVATE_KEY_ACCOUNT, provider);
    const subscriptionContract = new ethers.Contract(
      fujiConfig.ExpanderSubscriptionsAddress,
      ExpanderSubscriptions.abi,
      signer
    );
    const payoutTimeStamp = Date.now();
    const estimatedGasLimit = await subscriptionContract.estimateGas.payForCreatorSubscription(
      bytes32uniqueId,
      payoutTimeStamp,
      mumbaiConfig.PaymentAddress,
      fujiConfig.PaymentAddress,
      ethers.constants.AddressZero
    );
    const approveTxUnsigned =
      await subscriptionContract.populateTransaction.payForCreatorSubscription(
        bytes32uniqueId,
        payoutTimeStamp,
        mumbaiConfig.PaymentAddress,
        fujiConfig.PaymentAddress,
        ethers.constants.AddressZero
      );
    console.log(gas, gasPrice);
    approveTxUnsigned.chainId = fujiConfig.chainId;
    approveTxUnsigned.gasLimit = ethers.BigNumber.from('8000000');
    approveTxUnsigned.gasPrice = ethers.BigNumber.from(String(gasPrice));
    approveTxUnsigned.value = gas;
    console.log(gas);
    approveTxUnsigned.nonce = await provider.getTransactionCount(signer.address);
    const approveTxSigned = await signer.signTransaction(approveTxUnsigned);
    const submittedTx = await provider.sendTransaction(approveTxSigned);
    const approveReceipt = await submittedTx.wait();
    if (approveReceipt.status === 0) {
      throw new Error('Transaction failed');
    } else {
    }
  };

  const createSubscriptionOnContract = async (
    recurringAmount,
    payoutTimeStamp,
    bytes32uniqueId
  ) => {
    let web3 = await Moralis.enableWeb3({
      // provider: 'metamask'
      privateKey: PRIVATE_KEY_ACCOUNT
    });
    const subscriptionObject = {
      recurringAmount: recurringAmount,
      nextEligiblePayoutTimestamp: payoutTimeStamp,
      remainingPaymentTimestamps: ethers.BigNumber.from(duration),
      interval: frequency === 'weekly' ? 1658286598 : 1656299398
    };
    const payment = {
      chainName: fujiConfig.name,
      chainId: String(fujiConfig.chainId),
      walletAddress: Moralis.User.current().get('ethAddress'),
      tokenName: fujiConfig.availableTokens.aUSDC.tokenName,
      tokenSymbol: fujiConfig.availableTokens.aUSDC.tokenSymbol
    };
    const creator = {
      creatorAddress: props.creatorAddress,
      chainId: String(mumbaiConfig.chainId),
      chainName: mumbaiConfig.name
    };

    const options = {
      contractAddress: fujiConfig.ExpanderSubscriptionsAddress,
      functionName: 'addSubscription',
      abi: ExpanderSubscriptions.abi,
      params: {
        subscription: subscriptionObject,
        payment: payment,
        creator: creator,
        _uniqueId: bytes32uniqueId
      }
    };
    const transaction = await Moralis.executeFunction(options);
    const receipt = await transaction.wait();
    if (receipt.status == 1) {
      await handlePayment(bytes32uniqueId);
    }
  };

  const sleep = async (ms) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  };

  // A bit ugly, needs to cleaned up safer
  const handleSubscribe = async () => {
    let web3 = await Moralis.enableWeb3({
      provider: 'metamask'
    });

    if (!updateHook) {
      setUpdateHook(true);
    } else {
      setUpdateHook(false);
    }
    if (amountIncomplete || durationIncomplete || frequencyIncomplete) {
    } else {
      const approvalAmount = ethers.utils.parseUnits(String(Number(amount) * Number(duration)), 6);
      const recurringAmount = ethers.utils.parseUnits(String(Number(amount)), 6);
      const ERC20Options = {
        contractAddress: fujiConfig.availableTokens.aUSDC.tokenAddress,
        functionName: 'approve',
        abi: WETH.abi,
        params: {
          spender: fujiConfig.PaymentAddress,
          amount: approvalAmount
        }
      };
      try {
        const ERC20transaction = await Moralis.executeFunction(ERC20Options);
        const payoutTimeStamp = Date.now();
        const bytes32uniqueId = Web3.utils.soliditySha3(payoutTimeStamp + props.creatorAddress);

        const Subscription = Moralis.Object.extend('Subscription');
        const subscription = new Subscription();
        subscription.set('creatorId', props.creatorId);
        subscription.set('creatorAddress', props.creatorAddress);
        subscription.set('creatorChainId', mumbaiConfig.chainId);
        subscription.set('creatorchainName', mumbaiConfig.chainName);
        subscription.set('tokenSymbol', 'aUSDC');
        subscription.set('tokenAddress');
        subscription.set('senderAddress', Moralis.User.current().get('ethAddress'));
        subscription.set('amount', recurringAmount.toString());
        subscription.set('duration', duration);
        subscription.set('frequency', frequency);
        subscription.set('confirmation', 'confirmation');
        subscription.set('nextEligiblePayoutTimestamp', payoutTimeStamp);
        subscription.set('uniqueId', bytes32uniqueId);
        subscription.set('chainName', fujiConfig.chainId);
        subscription.save();
        navigate('/confirmation');
        await createSubscriptionOnContract(recurringAmount, payoutTimeStamp, bytes32uniqueId);

        // await handlePayment(bytes32uniqueId);
      } catch (err) {
        setAmountIncomplete(true);
      }
    }
  };

  const login = async () => {
    if (!isAuthenticated) {
      await authenticate({ signingMessage: 'Log in using Moralis' })
        .then(function (user) {})
        .catch(function (error) {});
    }
  };

  return (
    <div>
      <div className="checkout">
        <Card>
          <div className="checkoutCard">
            <div className="closeBtn" onClick={handleCloseBtn}>
              <img src={CrossBtn} alt="x" />
            </div>
            <div className="checkoutCard__header">
              <div className="dp">
                <img src={dp} alt="dp" />
              </div>
              <div className="name">
                <p>SUBSCRIBING TO</p>
                <h4>{props.creatorId}</h4>
                <h5> {props.creatorAddress}</h5>
              </div>
            </div>
            {!isAuthenticated ? (
              <div className="checkoutCard__connect">
                <h4>Please connect your wallet</h4>
                <div className="wallet">
                  <button onClick={login}>
                    <img src={metamask} alt="metamask" />
                    <h3>Connect MetaMask</h3>
                  </button>
                </div>
              </div>
            ) : (
              <>
                <div className="checkoutCard__form">
                  <div className="formItem">
                    <label>AMOUNT</label>
                    <div className="amountField">
                      <input onChange={handleAmountChange} value={amount} />
                      <div className="currency">
                        <select value={selectedToken} onChange={handleTokenChange}>
                          <option value={'default'} default>
                            Select token
                          </option>
                          {allTokensBalance.map((token) => {
                            return (
                              <option key={token.symbol} value={token.symbol}>
                                {token.symbol}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                    <div className="balance">
                      <h6>
                        Available balance: <span>{tokenBalance}</span>
                      </h6>
                    </div>
                    {amountIncomplete ? <p>This field is required</p> : null}
                  </div>
                  <div className="formItem">
                    <label>DURATION </label>
                    <input onChange={handleDurationChange} value={duration} />
                    {durationIncomplete ? <p>This field is required</p> : null}
                  </div>
                  <div className="formItem">
                    <label>FREQUENCY {frequencyIncomplete ? ' - cannot be blank' : null}</label>
                    <div className="frequency">
                      <div
                        className={`frequencyBtn ${frequency === 'weekly' ? 'active' : ''}`}
                        onClick={() => handleFrequencyClick('weekly')}>
                        WEEKLY
                      </div>
                      <div
                        className={`frequencyBtn ${frequency === 'monthly' ? 'active' : ''}`}
                        onClick={() => handleFrequencyClick('monthly')}>
                        MONTHLY
                      </div>
                    </div>
                    {/* {frequency === ''  ? <p>This field is required</p> : null} */}
                  </div>
                </div>
                <div className={`checkoutCard__submit ${!validForm ? 'disabledBtn' : ''}`}>
                  <button disabled={!validForm} onClick={handleSubscribe}>
                    Subscribe
                  </button>
                </div>
              </>
            )}
          </div>
        </Card>
      </div>
    </div>
  );
};

export default CheckoutCard;
