import React from 'react';
import FeedCard from '../homepage/feedCard/feedCard';
import ProfileStats from '../homepage/profileStats/profileStats';
import * as styles from './creatorFeed.module.scss';

const CreatorFeed = ({ subscribedUser, handleSubscribeClick, creatorAddress, creatorId }) => {
  return (
    <div className={styles.creatorFeed}>
      <div className={styles.container}>
        <div className={styles.profileStats}>
          <ProfileStats username={creatorId} address={creatorAddress} />
        </div>
        <div className={styles.feed}>
          {!subscribedUser && (
            <div className={styles.dialogOverlay}>
              <div className={styles.box}>
                <h4>Subscribe to this user to view their content.</h4>
                <button onClick={handleSubscribeClick}>Subscribe</button>
              </div>
            </div>
          )}
          <div className={`${styles.feedList} ${!subscribedUser ? `${styles.blurred}` : ''} `}>
            <FeedCard />
            <FeedCard />
            <FeedCard />
            <FeedCard />
            <FeedCard />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreatorFeed;
