import React from 'react';
import { useNavigate } from 'react-router-dom';
import dp from '../../assets/images/dp.webp';
import * as styles from './profile.module.scss';

const Profile = () => {
  const navigate = useNavigate();
  const handleHomeClick = () => {
    navigate('/home');
  };
  return (
    <div className={styles.profile}>
      <div className={styles.container}>
        <div className={styles.header}>
          <div className={styles.sectionName}>
            <h3>Profile</h3>
            <p>0x234husadDf98123kajsdf13bg</p>
          </div>
          <div className={styles.stats}>
            <div className={styles.items}>
              <h3>40,458</h3>
              <p>Subscribers</p>
            </div>
            <div className={styles.items}>
              <h3>10,458</h3>
              <p>Following</p>
            </div>
          </div>
        </div>
        <div className={styles.body}>
          <div className={styles.navigation}>
            <h5 onClick={handleHomeClick}>Home</h5>
          </div>
          <div className={styles.settingsForm}>
            <div className={styles.dpEditSection}>
              <div className={styles.dpChange}>
                <img src={dp} alt="dp" />
                <p>Upload picture</p>
              </div>
              <div className={styles.socialLinks}>
                <h5>Social links</h5>
                <input placeholder="Youtube" />
                <input placeholder="Youtube" />
                <input placeholder="Youtube" />
              </div>
            </div>
            <div className={styles.infoEditSection}>
              <div className={styles.formItem}>
                <label>Username</label>
                <input />
              </div>
              <div className={styles.formItem}>
                <label>Email</label>
                <div className={styles.emailVerify}>
                  <input />
                  <button>VERIFY</button>
                </div>
              </div>
              <div className={styles.formItem}>
                <label>Bio</label>
                <textarea />
              </div>
              <div className={styles.saveForm}>
                <button>Save</button>
              </div>
            </div>
            <div className={styles.manage}>
              <button>Manage account</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
