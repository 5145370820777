import React, { useEffect, useState } from 'react';
import Card from '../../components/card/card';
import metaMask from '../../assets/images/MetaMask_Fox.svg';
import crossBtn from '../../assets/images/cross.svg';
import * as styles from './login.module.scss';
import { useNavigate } from 'react-router-dom';
import { useMoralis, useMoralisWeb3Api } from 'react-moralis';

const Login = () => {
  const {
    authenticate,
    isAuthenticated,
    isAuthenticating,
    user,
    account,
    logout,
    Moralis,
    web3,
    isWeb3Enabled
  } = useMoralis();

  const Web3Api = useMoralisWeb3Api();
  const [currentUser, setCurrentUser] = useState(undefined);
  const [currentAccount, setCurrentAccount] = useState(undefined);

  useEffect(() => {
    if (isAuthenticated) {
      // add your logic here
      setCurrentUser(Moralis.User.current());
      setCurrentAccount(Moralis.User.current().get('ethAddress'));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  const login = async () => {
    if (!isAuthenticated) {
      await authenticate({ signingMessage: 'Log in using Moralis' })
        .then(function (user) {
          navigate('/home');
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  };

  const navigate = useNavigate();

  const handleCloseBtn = () => {
    navigate('/');
  };
  const handleWalletLogin = (wallet) => {
    login();
  };

  return (
    <div className={styles.login}>
      <Card>
        <div className={styles.container}>
          <div className={styles.closeBtn}>
            <img role="presentation" src={crossBtn} alt="x" onClick={handleCloseBtn} />
          </div>
          <div className={styles.title}>
            <h3>Login</h3>
            <p>Connect your wallet to login</p>
          </div>
          <div className={styles.walletOptions}>
            <div className={styles.wallet}>
              <button onClick={() => handleWalletLogin('metamask')}>
                <img src={metaMask} alt="metamask" />
                <h3>Connect MetaMask</h3>
              </button>
            </div>
          </div>
          <div className={styles.disclaimer}>
            <p>
              Having trouble logging in? Learn about setting up your Wallet <a href="#">here</a>
            </p>
          </div>
        </div>
      </Card>
    </div>
  );
};

export default Login;
