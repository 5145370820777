export const paymentContract = '0x5FbDB2315678afecb367f032d93F642f64180aa3';
export const subscriptionContractAddress = '0xe7f1725E7734CE288F8367e1Bb143E90bb3F0512';
export const ERC20Address = '0x9fE46736679d2D9a65F0992F2272dE9f3c7fa6e0';
export const creatorContractAddress = '0xCf7Ed3AccA5a467e9e704C703E8D87F634fB0Fc9';

const ENV = 'testnet';
const testnetStatus = ENV == 'testnet';

export const serverUrl = testnetStatus
  ? 'https://yzfmxhi7guo0.usemoralis.com:2053/server'
  : 'https://s5xr3we2doxv.usemoralis.com:2053/server';

export const appUrl = testnetStatus
  ? 'chS0PRtP6Nm3VIBT3mx9RzDZOKN7H1Dyw631aiX8'
  : 'VRK7DZeWZpvmOJ0fLptqZ2p8HJNfdw3oMnR3gqFA';

export const mumbaiConfig = {
  name: 'Polygon',
  chainId: 80001,
  rpc: 'https://rpc-mumbai.maticvigil.com',
  gateway: '0xBF62ef1486468a6bd26Dd669C06db43dEd5B849B',
  gasReceiver: '0xbE406F0189A0B4cf3A05C286473D23791Dd44Cc6',
  tokenName: 'Matic',
  tokenSymbol: 'MATIC',
  PaymentAddress: '0x5d8e5B15F04Ae466a94a86A5C8868951Ac7D4C8c',
  ExpanderSubscriptionsAddress: '0x006493dEBD9B8C92e26B286EF25E23548dDE44f4',
  ExpanderCreatorFactory: '0x1e3c59cFb4f8221ad8f6706c06088e013fb354E9',
  creatorAddress: '0x95f10C78eee9bB36b01EdA48C16f0409eD50F555'
};

export const fujiConfig = {
  name: 'Avalanche',
  rpc: 'https://api.avax.network/ext/bc/C/rpc',
  chainId: 43113,
  gateway: '0xC249632c2D40b9001FE907806902f63038B737Ab',
  gasReceiver: '0xbE406F0189A0B4cf3A05C286473D23791Dd44Cc6',
  tokenName: 'Avax',
  tokenSymbol: 'AVAX',
  PaymentAddress: '0x0054FADC74529D67D29Bd9708F49a6e91C795797',
  ExpanderSubscriptionsAddress: '0x266E35394eD7FEAE452d106A59386135941654E0',
  ExpanderCreatorFactoryAddress: '0x29e8f6cB5B5cE0b73fD07f22e531223A5E4A005c',
  USDCContractAddress: '0x57F1c63497AEe0bE305B8852b354CEc793da43bB',
  subscriberAddress: '0x0AFd276709AB4D47D71B48541b08a42Fb597cA86',
  availableTokens: {
    aUSDC: {
      tokenName: 'axlUSDC',
      tokenSymbol: 'aUSDC',
      tokenAddress: '0x57F1c63497AEe0bE305B8852b354CEc793da43bB'
    }
  }
};

export const PRIVATE_KEY_ACCOUNT =
  'f7a15a6ef2a307c02d3a07cb05dbd785ac6eebf9e5f5e23abe05a8a5fb1a95e4';
