import React from 'react';
import { useNavigate } from 'react-router-dom';
import Card from '../../components/card/card';
import * as styles from './landingpage.module.scss';
const Landingpage = () => {
  const navigate = useNavigate();
  const handleTryNow = () => {
    navigate('/signup');
  };
  return (
    <div className={styles.landing}>
      <div className={styles.hero}>
        <h2>Creators are meant to create.</h2>
        <h4>
          We help you manage cross-chain subscriptions with your fans <br />
          so you can focus on creating{' '}
        </h4>
        <button onClick={handleTryNow}>Try now!</button>
      </div>
      <div className={styles.container}>
        <div className={styles.section}>
          <div className={styles.first}>
            <h3>Content is borderless and so are your payments.</h3>
            <p>
              We believe in the collective idea of web3 and your loyal fans should not be limited by
              where they are or which chain they use to support you.
            </p>
          </div>
          <div className={styles.second}>
            <h3>Let your fans show their support</h3>
            <p>Easy subscriptions with virtually no setup required.</p>
            <div className={styles.steps}>
              <Card cardClassName={styles.stepCard}>
                <h4>Sign up</h4>
                <p>One click sign-up with your MetaMask wallet.</p>
              </Card>
              <Card cardClassName={styles.stepCard}>
                <h4>Share your page!</h4>
                <p>
                  Automatically get a page where your fans can view your content and share the link
                  with your fans
                </p>
              </Card>
              <Card cardClassName={styles.stepCard}>
                <h4>Low commision</h4>
                <p>We charge a flat 5% fee on every transaction. That's it. We pay for gas.</p>
              </Card>
            </div>
          </div>
          <div className={styles.third}>
            <h3>Powered by</h3>
            <div className={styles.image}>
              <svg width="170" height="40" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#logo_svg__a)">
                  <path
                    d="m51.583 16.842 12.28-12.21L59.205 0l-9.951 9.894L39.303 0l-4.66 4.632 12.281 12.21c.644.64 1.487.96 2.33.96s1.686-.32 2.329-.96Zm17.778 13.043-9.951-9.893 9.95-9.894-4.658-4.632-12.28 12.21a3.262 3.262 0 0 0 0 4.632l12.28 12.21 4.659-4.633Zm-20.124.221L59.187 40l4.66-4.632-12.281-12.21a3.308 3.308 0 0 0-4.659 0l-12.28 12.21L39.285 40l9.952-9.894Zm-3.168-7.782a3.266 3.266 0 0 0 0-4.631l-12.28-12.21-4.66 4.632 9.952 9.893-9.951 9.894 4.658 4.632 12.28-12.21Zm-17.105 16.07H18.636l-.743-4.67H11.16l-.815 4.67H.018l8.89-36.787h12.22l7.836 36.786ZM16.719 25.837l-.647-4.289c-.017-.015-.048-.23-.096-.644-.031-.348-.2-1.73-.503-4.144-.032-.27-.072-.64-.12-1.108-.048-.47-.112-1.062-.191-1.777l-.313-3.477c-.03.334-.075.707-.131 1.12-.056.413-.116.865-.18 1.358a203.2 203.2 0 0 1-.24 2.05c-.048.379-.079.61-.095.69l-.695 4.454-1.006 5.767h4.217Zm73.968 12.555v-8.838h-8.578v-5.624h7.69v-8.313h-7.69v-5.36h8.578v-8.65h-18.57v36.785h18.57Zm19.653-8.838h-7.5V1.607h-9.992v36.786h17.492v-8.838Zm30.683 8.839h-10.326l-.744-4.67h-6.733l-.815 4.67h-10.328l8.89-36.787h12.221l7.835 36.786ZM128.78 25.838l-.648-4.289c-.017-.015-.048-.23-.096-.644-.031-.348-.2-1.73-.503-4.144-.033-.27-.071-.64-.12-1.108-.048-.47-.112-1.062-.19-1.777l-.314-3.476c-.031.333-.075.706-.131 1.12-.056.412-.116.864-.18 1.358-.112.984-.192 1.667-.24 2.048-.048.38-.079.611-.094.691l-.696 4.454-1.007 5.767h4.219Zm40.711 12.556h-10.806L153.75 25.29a9.296 9.296 0 0 0-.254-.882 88.334 88.334 0 0 0-.442-1.31l.071 2.43v12.866h-9.944V1.607h10.232c4.505 0 7.875.882 10.113 2.646 2.842 2.255 4.264 5.464 4.264 9.623 0 4.449-1.909 7.523-5.727 9.222l7.428 15.295ZM157.87 13.996c0-2.747-1.446-4.12-4.337-4.12h-.36v8.766h.671c1.15 0 2.109-.436 2.876-1.31.767-.873 1.15-1.985 1.15-3.336Z"
                    fill="#000"></path>
                </g>
                <defs>
                  <clipPath id="logo_svg__a">
                    <path fill="#fff" transform="translate(.018)" d="M0 0h169.474v40H0z"></path>
                  </clipPath>
                </defs>
              </svg>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.footer}>
        <p>We are in beta testing, reach out to us if you would like to join the beta.</p>
        All rights reserved.
      </div>
    </div>
  );
};

export default Landingpage;
