import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import CheckoutCard from '../../components/checkout/checkoutCard';
import CreatorFeed from '../../components/creatorFeed/creatorFeed';
import * as styles from './subscriber.module.scss';

const Subscribe = ({ subscribedUser, ...props }) => {
  const [showPopup, setShowPopup] = useState(!subscribedUser);
  useEffect(() => {
    setShowPopup(!subscribedUser);
  }, [subscribedUser]);
  const handleClosePopup = () => {
    setShowPopup(false);
  };
  const handleShowPopup = () => {
    setShowPopup(true);
  };
  return (
    <div>
      {showPopup && (
        <div className={styles.subscribeCard}>
          <CheckoutCard
            creatorAddress={props.creatorAddress}
            creatorId={props.creatorId}
            handleCloseBtn={handleClosePopup}
          />
        </div>
      )}
      <div className={`${showPopup ? `${styles.blurred}` : ''}`}>
        <CreatorFeed
          subscribedUser={subscribedUser}
          handleSubscribeClick={handleShowPopup}
          creatorAddress={props.creatorAddress}
          creatorId={props.creatorId}
        />
      </div>
    </div>
  );
};

export default Subscribe;
