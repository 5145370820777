import "./App.css";
import { Routing } from "./pages/routing";

function App(props) {
  return (
    <div>
      <Routing />
    </div>
  );
}

export default App;
