import React from 'react';
import Card from '../card/card';
import './subscriberCard.scss';
import dp from '../../assets/images/dp.webp';

const SubscriberCard = () => {
  return (
    <div>
      <div className="subscriberCard">
        <Card>
          <>
            <div className="subscriberCard__dp">
              <img src={dp} alt="x" />
              <h4>John Doe</h4>
            </div>
            <div className="subscriberCard__stats">
              <div className="statItem">
                <h4>40</h4>
                <h5>POSTS</h5>
              </div>
              <div className="statItem">
                <h4>250K</h4>
                <h5>SUBSCRIBERS</h5>
              </div>
            </div>
            <div className="subscriberCard__buttons">
              <button>Subscribe Now</button>
              <button>One-time payment</button>
            </div>
          </>
        </Card>
      </div>
    </div>
  );
};

export default SubscriberCard;
